.navbar {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 60px;
}

.navbar__container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu {
  display: flex;
  display: flex;
  gap: 1rem;
}

.nav-title {
  cursor: pointer;
}

.nav-title a:hover,
.nav-title a:focus,
.nav-title a {
  color: white;
}

@media screen and (max-width: 580px) {
  .navbar {
    height: unset;
    padding: 1rem 0;
  }
  .navbar__container {
    flex-direction: column;
  }

  .menu {
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
  }
}
