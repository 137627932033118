@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@400;500&display=swap");

*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  font-size: 16px;
  font-family: "Lexend Deca", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  min-height: 100vh;
  background-color: #0e1010;
  color: #efefef;
  padding-bottom: 4em;
}

ul,
li {
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  color: rgb(128, 204, 255);
  cursor: pointer;
  transition: 300ms;
}

a:hover,
a:focus {
  color: aquamarine;
}

p {
  line-height: 2;
}

.btn,
button {
  display: inline-block;
  padding: 1em 1.5em;
  font-size: 1em;
  letter-spacing: -0.5px;
  font-weight: bold;
  margin: 1rem 0;
  cursor: pointer;
  border: none;
  transition: 400ms;
}

.btn-primary {
  background-color: rgb(115, 0, 255);
  border: 2px solid rgb(129, 63, 233);
  color: white;
}

.btn-primary:hover,
.btn-primary:focus {
  background-color: rgb(115, 0, 255);
  border: 2px solid rgb(197, 161, 255);
  color: white;
}

.btn-secondary {
  border: 2px solid gray;
  background-color: transparent;
  color: white;
}
.btn-secondary:hover,
.btn-secondary:focus {
  border-color: white;
  color: white;
}

.container {
  max-width: 900px;
  margin: 0 auto;
  padding: 0 1rem;
}

header {
  position: relative;
  background-color: #000000a4;
  padding: 4em 0;
  min-height: 75vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 100px;
  border-radius: 0 0 80px 80px;
}

header h4 {
  opacity: 0.5;
}

header .intro-text {
  font-size: 3em;
  line-height: 1.5em;
  z-index: 1;
}

header::before {
  content: "";
  position: absolute;
  height: 100px;
  border-right: 5px dotted #909090;
  bottom: 0;
  translate: -50% 50%;
}

header::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-image: url("/src/assets/img/banner.gif");
  background-size: cover;
  z-index: -1;
  border-radius: 0 0 80px 80px;
}

section {
  padding: 1.75rem 0;
}

section p {
  width: 80%;
}

.cta-section {
  background-color: #1a1b1a;
}

.cta__container {
  text-align: center;
}

.cta__story-preview {
  position: relative;
  color: gray;
  padding: 1rem 0;
}

.cta__story-preview::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(#1a1b1a00 0%, #1a1b1a 80%);
}

@media screen and (max-width: 580px) {
  html {
    font-size: 15px;
  }
  section p {
    width: 100%;
  }

  h2 {
    text-align: center;
  }

  button,
  .btn {
    display: block;
    margin: 2em auto;
    justify-self: center;
    max-width: 300px;
    text-align: center;
  }

  header {
    padding-top: 110px;
    font-size: 0.8rem;
    margin-bottom: 70px;
  }
}
