.story-container {
  display: grid;
  grid-template-columns: 100px 1fr;
  gap: 2rem;
}

.story__word {
  width: 100%;
  text-transform: capitalize;
  color: #504f4f;
  text-align: right;
}
